import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { pageTitles } from "../../../data/data";

const SubMenu = ({ icon, title, items, allChildrenPath, navigateToLink }) => {
  
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(allChildrenPath.includes(location.pathname) ? false : true);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed }) + " sidebar-item"}
      >
        <NavLink
          className={`dropdown-toggle`}
          style={{
            backgroundColor: !collapsed ? "#ebebeb" : "",
            borderRadius: "0px",
          }}
        >
          <Icon
            icon={icon}
            fontSize={23}
            style={{ margin: "0px 4px 3px 0px", color: "#5b5b5b" }}
          />
          {title}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {/* <div className="border-top mx-2"></div> */}
        {items.map((item, index) => (
          <NavItem
            key={index}
            onClick={e => navigateToLink(item?.path)}
            className="d-flex align-item-center sidebar-item"
            style={{
              paddingInline: "25px",
              backgroundColor: location.pathname === item.path ? "#4c7ee8" : "",
            }}
          >
            <Icon
              icon={
                location.pathname === item.path
                  ? item?.imageSecond
                  : item?.imageOne
              }
              fontSize={23}
              style={{
                color: location.pathname === item.path ? "white" : "#5b5b5b",
                alignSelf: "center",
              }}
            />
            <NavLink
              tag={Link}
              // to={item.path}
              style={{
                color: location.pathname === item.path ? "white" : "",
              }}
            >
              {item.title}
            </NavLink>
          </NavItem>
        ))}
        {/* <div className="border-top mx-2"></div> */}
      </Collapse>
    </div>
  );
};

export default SubMenu;
