import { useEffect, useState } from "react";
import NotificationFilter from "./filter";
import NotificationMessage from "./notificationMessage";

const SendNotification = () => {
  const [allFilter, setAllFilter] = useState({
    sendOn: "app",
    sendTo: "1",
    users: [],
  });
  useEffect(() => {
    console.log(allFilter);
  }, [allFilter]);
  return (
    <div class="overview Notifications mb-0">
      <div className="fs-3 mb-4 fw-bold">{"Send notifications"}</div>
      <div class="send_noti">
        <NotificationFilter allFilter={allFilter} setAllFilter={setAllFilter} />
        <NotificationMessage
          heading={"Make your notification"}
          allFilter={allFilter}
          setAllFilter={setAllFilter}
        />
      </div>
    </div>
  );
};
export default SendNotification;
