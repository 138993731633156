import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

function MultilingualBox({ title, valueObj }) {
  const [lang, setLang] = useState("en");
  const [readMore, setReadMore] = useState(false);
  return (
    <p>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        {title}
        <div>
          <ButtonGroup className="border">
            <Button
              onClick={(e) => setLang("en")}
              color={lang === "en" ? "secondary" : "light"}
              style={{
                fontSize: "12px",
                padding: "3px 7px",
                color: lang === "en" ? "white" : "#5b5b5b",
              }}
            >
              English
            </Button>
            <Button
              onClick={(e) => setLang("ar")}
              color={lang === "ar" ? "secondary" : "light"}
              style={{
                fontSize: "12px",
                padding: "3px 7px",
                color: lang === "ar" ? "white" : "#5b5b5b",
              }}
            >
              Arabic
            </Button>
            <Button
              onClick={(e) => setLang("ku")}
              color={lang === "ku" ? "secondary" : "light"}
              style={{
                fontSize: "12px",
                padding: "3px 7px",
                color: lang === "ku" ? "white" : "#5b5b5b",
              }}
            >
              Kurdish
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div dir={lang === "en" ? "ltr" : "rtl"}>
        <>
          <p
            style={{
              display: "-webkit-box",
              WebkitLineClamp: readMore ? 100 : 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              // idth: "350px",
            }}
          >
            {valueObj?.[lang] ? valueObj?.[lang] : "--"}
          </p>
        </>
      </div>
      {valueObj?.[lang]?.length > 190 && (
        <a
          className="mb-3 text-primary"
          style={{ margin: "0px", textDecoration: "none" }}
          onClick={() => {
            setReadMore(!readMore);
          }}
        >
          {readMore ? "See less" : "See more"}
        </a>
      )}
    </p>
  );
}

export default MultilingualBox;
