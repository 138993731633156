import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  getStatusList,
  pendingApprovalActionFun,
  sendEmail,
  sendWhatsAppMessage,
  setKycStatus,
} from "../../../services/api";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import "react-toastify/dist/ReactToastify.css";
import BoostrapModel from "../../Tools/BoostrapModel";
import KycModel from "./model";
import KycBox from "../../Tools/KycBox";
import Confirm from "./confirm";
import { kycMsgs } from "../../../data/translations";
const KycDocument = ({ title1, title2, image, buttonName, row }) => {
  const [show, setShow] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  useEffect(() => {
    getStatusList().then((res) => {
      if (res?.status) {
        setStatusList(res?.data);
      }
    });
  }, []);
  const openModal = (type, data) => {
    const md = {
      type: type,
      key:
        type === "approve"
          ? "Submitted and verified"
          : "Submitted and rejected",
      description:
        type === "approve"
          ? "Are you sure you want to approve this user?"
          : "Are you sure you want to reject this user?",
      data: data,
    };
    setModalData(md);
    setModal(true);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const statusId = statusList?.filter((v) =>
      v?.name?.en.includes(data?.key)
    )?.[0]?._id;
    const apiData = {
      kycStatus: statusId,
      userId: row?._id,
    };
    setKycStatus(apiData).then((res) => {
      if (res?.status) {
        let template = kycMsgs?.[data?.type]?.[data?.lang]
          .replace("{{username}}", row?.fullName)
          .replace("{{message}}", data?.body);

        // sendEmail({
        //   subject: data?.title,
        //   message: data?.body,
        //   userIds: [row?._id],
        //   type: "0",
        //   attachments: selectedFile.map((v) => v?.s3URL),
        // });
        sendWhatsAppMessage({
          phoneNumber: row?.phoneNumber,
          message: template
        }, data?.lang)
        swal("Success", res?.message, "success");
        setModal(false);
        navigate("/approval_que");
      } else {
        swal("Error", res?.message, "error");
      }
    });

    // const res = {
    //   phoneNumber: row?.phoneNumber,
    //   message: template
    // }

    // console.log(data, row, res);
  };

  console.log(row?.preferredLanguage)

  const setStatusWithoutMail = (key) => {
    const statusId = statusList?.filter((v) => v?.name?.en.includes(key))?.[0]
      ?._id;
    const apiData = {
      kycStatus: statusId,
      userId: row?._id,
    };
    setKycStatus(apiData).then((res) => {
      if (res?.status) {
        swal("Success", res?.message, "success");
        setModal(false);
        navigate("/approval_que");
      } else {
        swal("Error", res?.message, "error");
      }
    });
  };
  return (
    <div class="row">
      <KycBox
        parentStyle={"col-lg-6 col-xl-5 col-xxl-4 mb-4"}
        childStyle={"Documents"}
        title1={"KYC documents"}
        title2={"Documents"}
        onClick={() => setShow(true)}
        image={"../assets/images/eye2.png"}
      />
      <div class="col-sm-12 mt-4 text-end">
        <a class="btn" onClick={() => openModal("reject", row)}>
          Reject
        </a>
        <a class="btn approve" onClick={() => openModal("approve", row)}>
          Approve
        </a>
      </div>
      <BoostrapModel
        show={show}
        heading={`Kyc for ${row?.dealerShipName}`}
        style={"warning warningKyc"}
        component={<KycModel row={row} />}
        onHide={() => setShow(false)}
      />
      <BoostrapModel
        show={modal}
        heading={"Confirm.. !?"}
        style={"warning warningKyc "}
        fullWidth
        component={
          <Confirm
            data={modalData}
            handleSubmit={handleSubmit}
            closeModal={() => setModal(false)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setStatusWithoutMail={setStatusWithoutMail}
            preferredLang={row?.preferredLanguage || "en"}
          />
        }
        onHide={() => setModal(false)}
      />
    </div>
  );
};

export default KycDocument;
