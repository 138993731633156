import React from "react";
import ListItem from "../../Tools/ListItem";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { openAddressOnMap } from "../../../data/utils";

export default function SinglePageRight({
  classNames,
  page,
  data,
  radioState,
  handleChange,
  getPrice,
  lang,
  params,
  handleInitiateChat,
  chatNow,
  setChatNow,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const udata = useSelector((state) => state.auth);
  console.log(radioState);

  const getRadioValue = () => {
    return (
      <ul
        class="my-3 d-flex"
        style={{ direction: lang === "en" ? null : "rtl", height: "30px" }}
      >
        <>
          <label
            htmlFor="dailyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "daily" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="dailyRadio"
              name="quality"
              checked={radioState === "daily"}
              onChange={handleChange}
              class="quality delivery"
              value="daily"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>{"Daily"}</span>
          </label>
          <label
            htmlFor="weeklyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "weekly" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="weeklyRadio"
              name="quality"
              checked={radioState === "weekly"}
              onChange={handleChange}
              class="quality delivery"
              value="weekly"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>
              {"Weekly"}
            </span>
          </label>
          <label
            htmlFor="monthlyRadio"
            className={`my-radio m${lang === "en" ? "e" : "s"}-3`}
            style={{ cursor: "pointer" }}
          >
            <div className="my-radio-icon">
              <div
                className={`my-radio-icon-inner ${
                  radioState === "monthly" && "my-radio-active"
                }`}
              ></div>
            </div>
            <input
              type="checkbox"
              id="monthlyRadio"
              name="quality"
              checked={radioState === "monthly"}
              onChange={handleChange}
              class="quality delivery"
              value="monthly"
              hidden
            />
            <span className={`m${lang === "en" ? "s" : "e"}-1`}>
              {"Monthly"}
            </span>
          </label>
        </>
      </ul>
    );
  };

  return (
    <div className={classNames}>
      <div className="row flex justify-content-between align-items-start">
        <div className="col-lg-7">
          <h1 className="fw-semibold small-title">
            {data?.properties?.basicDetails?.make?.[lang] +
              " " +
              data?.properties?.basicDetails?.model?.[lang]}
          </h1>
          <h1 className="fw-bolder fs-3 mt-3">
            {data?.properties?.basicDetails?.makeYear}
          </h1>
          {page === "rental" ? getRadioValue() : null}
          <h2>{getPrice()}</h2>
        </div>
        <div className="col-lg-5 float-end px-0">
          <div
            style={{
              backgroundColor: "#F0F0F0",
              width: "fit-content",
              height: "70px",
              marginLeft: "0px",
            }}
            className="rounded-2 d-flex justify-content-center float-lg-end"
            // onClick={() => {
            //   if (data?.owner?.userType === "dealer") {
            //     navigate(`/search_result_page?id=${data?.owner?._id}`);
            //   }
            // }}
          >
            <div className="d-flex justify-content-center align-items-center mx-3">
              <img
                src={data?.owner?.coverPicture || data?.owner?.profilePicture || "../assets/images/ap2.png"}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                className="rounded-circle p-1 bg-light"
              />
            </div>
            <div
              className={`d-flex align-items-center m${
                lang === "en" ? "e" : "s"
              }-3`}
            >
              <span
                className={`fw-semibold fs-5`}
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {(data?.owner?.dealerShipName !== "N/A" &&
                  data?.owner?.dealerShipName) ||
                  data?.owner?.fullName}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* <a
        href={`/search_result_page?id=${data?.owner?._id}`}
        class="Likeston"
      >
        <img
          src={
            data?.owner?.profilePicture || "../assets/images/ap2.png"
          }
          className="rounded-5"
        />
        {data?.owner?.dealerShipName}
      </a> */}
    </div>
  );
}
