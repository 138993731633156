import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TabNavItem from "../../Tools/TabNavItem";
import { pricingFun } from "../../../Reducer/pricing/pricingSlice";
import Subscription from "./subscription";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditForm from "./editForm";
import AddForm from "./addForm";
import { updateSubscriptionPlan } from "../../../services/api";
import swal from "sweetalert";
import PriceSkeletons from "../../Tools/Skeletons/PriceSkeletons";

const Pricing = () => {
  const [show, setShow] = React.useState(false);
  const [addShow, setAddShow] = React.useState(false);
  const data = useSelector((state) => state.pricing?.data);
  const submitting = useSelector((state) => state.pricing?.submitting);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = React.useState("dealer");
  // const [data, setData] = React.useState([]);
  const [modalData, setModalData] = React.useState({});
  const dispatch = useDispatch();
  const [sellRent, setSellRent] = useState("Sell");
  const getData = () => {
    dispatch(
      pricingFun({
        adType: active === "checkMyCar" ? "Rent" : sellRent,
        type: active,
      })
    );
  };
  React.useEffect(() => {
    getData();
  }, [dispatch, sellRent, active]);

  const onClick = (data) => {
    setModalData(data);
    setShow(true);
  };
  const handleSubmit = (data1) => {
    const formData = {
      price: data1?.price,
    };
    setLoading(true);
    updateSubscriptionPlan(formData, modalData?._id).then((res) => {
      if (res?.status) {
        getData();
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };
  return (
    <div class="dashnboard pricing">
      <div className="fs-3 mb-4 fw-bold">{"Pricing Controls"}</div>
      <h3 className="d-flex justify-content-between flex-wrap">
        <div
          class="btn-group border my-tabs"
          style={{ borderRadius: "4px", height: "43px" }}
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class={`btn btn-light ${active == "dealer" ? "activeButton" : ""}`}
            style={{ width: "150px" }}
            onClick={() => {
              setActive("dealer");
              // setData(tradeUsers);
            }}
          >
            Dealer
          </button>
          <button
            type="button"
            class={`btn btn-light ${
              active == "individual" ? "activeButton" : ""
            }`}
            style={{ width: "150px" }}
            onClick={() => {
              setActive("individual");
              // setData(indiviualUsers);
            }}
          >
            Private users
          </button>
          <button
            type="button"
            class={`btn btn-light ${
              active == "checkMyCar" ? "activeButton" : ""
            }`}
            style={{ width: "150px" }}
            onClick={() => {
              setActive("checkMyCar");
              // setData(indiviualUsers);
            }}
          >
            Check my car
          </button>
        </div>
        {active !== "checkMyCar" && (
          <div
            class="btn-group border my-tabs"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class={`btn btn-light ${
                sellRent == "Sell" ? "activeButton" : ""
              }`}
              style={{ width: "121px" }}
              onClick={() => {
                setSellRent("Sell");
              }}
            >
              Sell
            </button>
            <button
              type="button"
              class={`btn btn-light ${
                sellRent == "Rent" ? "activeButton" : ""
              }`}
              style={{ width: "121px" }}
              onClick={() => {
                setSellRent("Rent");
              }}
            >
              Rent
            </button>
          </div>
        )}
        {/* <ul
          class="nav nav-tabs"
          id="myTab"
          role="tablist"
          style={{ display: "block", position: "unset" }}
        >
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${!sellRent && "active"} ${
              sellRent == "Sell" && "active"
            }`}
            onClick={() => {
              setSellRent("Sell");
              // setData(tradeUsers);
            }}
            title={"Sell"}
          />
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${sellRent == "Rent" && "active"}`}
            onClick={() => {
              setSellRent("Rent");
              // setData(indiviualUsers);
            }}
            title={"Rent"}
          />
        </ul> */}
        {/* <ul
          class="nav nav-tabs"
          id="myTab"
          role="tablist"
          style={{ display: "block", position: "unset" }}
        >
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${!active && "active"} ${active == "dealer" && "active"}`}
            onClick={() => {
              setActive("dealer");
              // setData(tradeUsers);
            }}
            title={"Dealer"}
          />
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${active == "individual" && "active"}`}
            onClick={() => {
              setActive("individual");
              // setData(indiviualUsers);
            }}
            title={"Private users"}
          />
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${active == "checkMyCar" && "active"}`}
            onClick={() => {
              setActive("checkMyCar");
              // setData(indiviualUsers);
            }}
            title={"Check my car"}
          />
        </ul> */}
        {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${!active && "active"} ${
              active == "trade" && "active"
            }`}
            onClick={() => {
              setActive("trade");
              // setData(tradeUsers);
            }}
            title={"Trade Users"}
          />
          <TabNavItem
            parentStyle={"nav-item"}
            childStyle={`nav-item ${active == "Individual" && "active"}`}
            onClick={() => {
              setActive("Individual");
              // setData(indiviualUsers);
            }}
            title={"Individual Users"}
          />
        </ul> */}
        {/* <a href="#" class="freeze_btn" onClick={() => setAddShow(!addShow)}>
          {"Add plans"}
        </a>
        &nbsp;
        <a href="#" class="freeze_btn">
          {"Freeze plans"}
        </a> */}
      </h3>
      {submitting ? (
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
        </div>
      ) : (
        <Subscription data={data || []} type={active} onClick={onClick} />
      )}
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={show}
        component={
          <EditForm
            rawData={modalData}
            onHide={() => setShow(false)}
            handleSubmitForm={handleSubmit}
            loading={loading}
          />
        }
        onHide={() => setShow(!show)}
        heading={`Edit plan - ${modalData?.name}`}
      />
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={addShow}
        component={<AddForm onHide={() => setAddShow(!addShow)} />}
        onHide={() => setAddShow(!addShow)}
        heading={"Edit plan"}
      />
    </div>
  );
};
export default Pricing;
