export const formatDashboardData = (data) => {
  console.log(data);
  const formattedData = {
    overview: [
      {
        title: "Total active ads",
        name: "totalActiveAds",
        value: data?.overview?.totalActiveAds,
        hasMore: true,
        more: [
          {
            title: "Sell",
            value: data?.overview?.totalSellAds,
            badgeColor: "#437ee8"
          },
          {
            title: "Rent",
            value: data?.overview?.totalRentedAds,
            badgeColor: "#437ee8"
          }
        ]
      },
      {
        title: "Total users",
        name: "totalUsers",
        value: data?.overview?.totalUsers,
      },
      {
        title: "Total dealers",
        name: "totalDealers",
        value: data?.overview?.totalDealers,
      },
      {
        title: "Total private users",
        name: "totalPrivateUsers",
        value: data?.overview?.totalPrivateUsers,
      },
      {
        title: "Total Views",
        name: "totalViews",
        value: data?.overview?.totalViews,
      },
      {
        title: "Total VIN searches",
        name: "totalViews",
        value: data?.overview?.totalVinTransactions,
      },
      {
        title: "Total payment recieved through ad postings",
        name: "totalViews",
        value: `$${(data?.overview?.totalPaymentRecieved?.totalAdsSum)?.toFixed(
          2
        )}`,
      },
      {
        title: "Total payment recieved through VIN searches",
        name: "totalViews",
        value: `$${(data?.overview?.totalPaymentRecieved?.totalVinSum)?.toFixed(
          2
        )}`,
      },
      {
        title: "Total payment received",
        name: "totalPaymentRecieved",
        value:
          "$" +
          (
            data?.overview?.totalPaymentRecieved?.totalVinSum +
            data?.overview?.totalPaymentRecieved?.totalAdsSum
          )?.toFixed(2),
        currency: "$",
      },
    ],
    keyStats: {
      dealer: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats?.dealer?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats?.dealer?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats?.dealer?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats?.dealer?.basic,
          },
        ],
        additionalInfo: [
          {
            content:
              data?.keyStats?.dealer?.additionalInfo?.overallAds +
              " ads posted so far",
            image: "akar-icons_info-fill.png",
          },
          {
            content:
              data?.keyStats?.dealer?.additionalInfo?.soldAds + " cars sold",
            image: "akar-icons_info-fill.png",
          },
          {
            content:
              data?.keyStats?.dealer?.additionalInfo?.deletedAds + " cars deleted",
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      users: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats?.users?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats?.users?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats?.users?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats?.users?.basic,
          },
        ],
        additionalInfo: [
          {
            content:
              data?.keyStats?.users?.additionalInfo?.overallAds +
              " ads posted so far",
            image: "akar-icons_info-fill.png",
          },
          {
            content:
              data?.keyStats?.users?.additionalInfo?.soldAds + " cars sold",
            image: "akar-icons_info-fill.png",
          },
          {
            content:
              data?.keyStats?.users?.additionalInfo?.deletedAds + " cars deleted",
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      total_car_sold: {
        title: " Cars sold so far (dealers  &  private users)",
        name: "totalSoldCars",
        value:
          data?.keyStats?.dealer?.additionalInfo?.soldAds +
          data?.keyStats?.users?.additionalInfo?.soldAds,
      },
    },
    metrics: [
      {
        title: "Views on selling cars",
        name: "totalSellingViews",
        value: data?.metrics?.totalSellingViews,
      },
      {
        title: "Views on rental cars",
        name: "totalRentingViews",
        value: data?.metrics?.totalRentingViews,
      },
      {
        title: "Views on checking cars",
        name: "totalCheckCarViews",
        value: data?.metrics?.totalCheckCarViews,
      },
      {
        title: "Views on website",
        name: "websiteViews",
        value: data?.metrics?.websiteViews,
      },
      {
        title: "Views on app",
        name: "mobileAppViews",
        value: data?.metrics?.mobileAppViews,
      },
      //   {
      //     title: "Total views",
      //     name: "totalVisits",
      //     value: data?.metrics?.totalVisits,
      //   },
    ],
    graphs: {
      liveTrafficGraph: {
        title: "Live traffic",
        description: "Product trends by month",
        data: [10000, 15000, 20000, 15000, 50000, 30000, 40000],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      soldGraph: {
        title: "Selling rate",
        description: "Products sold by month",
        data: [800, 150, 200, 150, 200, 300, 900],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      rentedGraph: {
        title: "Renting rate",
        description: "Products rented by month",
        data: [400, 150, 200, 150, 300, 300, 400],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    },
  };
  return formattedData;
};
