import React, { useEffect, useState } from "react";
import BasicTable from "../../Tools/Table";
import { makesTableColoumns } from "../../../data/data";
import BoostrapModel from "../../Tools/BoostrapModel";
import { Icon } from "@iconify/react/dist/iconify.js";
import { apiManager } from "../../../data/apiHandler";
import { ITEM_PER_PAGE } from "../../../data/varible";
import PreferencesModal from "./preferencesModal";
import swal from "sweetalert";
import { Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import EditMakeModal from "../ViewMake/editMakeModal";
import { useDidMountEffectWithDebounce } from "../../../data/hooks";
const Makes = () => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const limit = ITEM_PER_PAGE;
  const [totalPages, setTotalPages] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  //modal state
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setTimeout(() => {
      setModalData(null);
    }, 400);
  };
  const getData = (val) => {
    const searchVal = val || "";
    setLoading(true);
    apiManager(
      "GET",
      `general/makes?lang=en&page=${currentPage}&limit=${limit}&name=${searchVal}`
    )
      .then((res) => {
        if (res?.status) {
          const dataT = res?.data?.makes;
          setData(
            dataT.map((v) => ({
              name_en: v?.name,
              name_ar: v?.name,
              name_ku: v?.name,
              _id: v?.name,
              rawData: v,
              logo: <img src={v?.image} height={28} />,
            }))
          );
          setTotalPages(res?.data?.totalPages);
        } else {
          setData([]);
          console.log(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, [currentPage]);

  const actions = [
    {
      name: "edit",
      label: (
        <Icon
          icon="bxs:message-square-edit"
          fontSize={24}
          style={{ color: "#437ee8" }}
        />
      ),
      onclick: (id, rawData) => {
        console.log("id", id, rawData);
        openModal({ type: "edit", title: "Edit Make", item: rawData });
      },
      type: "text",
    },
    {
      name: "delete",
      label: (
        <Icon
          icon="ri:delete-bin-4-fill"
          fontSize={25}
          className="mx-2"
          style={{ color: "#ff6666" }}
        />
      ),
      style: "Remove",
      onclick: (id, rawData) => {
        console.log("id", id);
        openModal({ type: "delete", title: "Delete Make", item: rawData });
      },
      type: "text",
    },
  ];

  // Function to search products
  useDidMountEffectWithDebounce(() => {
    getData(searchValue);
  }, [searchValue], 1000);

  const view = {
    label: "",
    image: "eye2.png",
    onclick: (id) => null,
    path: "/view_make",
    disabled: true,
  };

  const handleSubmit = (data) => {
    setFormLoading(true);
    console.log(data, "adder");
    if (modalData?.type === "add") {
      apiManager("POST", `general/make?lang=en`, data)
        .then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
            getData();
            closeModal();
          } else {
            swal("Error", res?.message, "error");
            getData();
          }
          setFormLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal("Error", err?.message, "error");
          getData();
          setFormLoading(false);
        });
    }
  };
  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">{"Specifications"}</div>
      <div class="set_list2 mb-0">
        <h3 class="" style={{ height: "unset" }}>
          <div className="row">
            <div className="col-lg-6">{"Makes"}</div>
            <div className="col-lg-6 col-sm-12 d-sm-flex d-block ">
              <div
                className="col-lg-6 d-flex justify-content-center align-items-center"
                style={{ marginLeft: "0px", textDecoration: "none" }}
              >
                <Input
                  className="mx-sm-3 mx-0 my-3 my-sm-0 w-100"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder="Search"
                />
              </div>
              <div
                className="col-lg-6 btn btn-primary d-flex justify-content-center align-items-center"
                style={{
                  marginLeft: "0px",
                  textDecoration: "none",
                  backgroundColor: "#4c7ee8",
                }}
                onClick={() => openModal({ type: "add", title: "Add Make" })}
              >
                Add Make
              </div>
            </div>
          </div>
        </h3>
        <BasicTable
          columns={makesTableColoumns}
          rows={data || []}
          view={view}
          // actions={actions}
          submitting={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemperpage={limit}
          onRowClick={(e) => {
            console.log(e);
            navigate(`${view.path}?id=${e?._id}${view?.moreParams || ""}`, {
              state: e.rawData,
            });
          }}
        />

        <BoostrapModel
          show={modal}
          heading={modalData?.title}
          component={
            <EditMakeModal
              submitCallback={handleSubmit}
              data={modalData}
              loading={formLoading}
            />
          }
          onHide={closeModal}
        />
      </div>
    </div>
  );
};
export default Makes;
